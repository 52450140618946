import React from "react";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  dots: {
    display: "flex",
  },
  dot: {
    width: "3rem",
    height: "3rem",
    color: "green",
    backgroundColor: "rgb(57, 54, 239)",
    borderRadius: "50%",
    margin: theme.spacing(0.5),
    animation: "$blink 1s infinite",
  },
  loader: {
    color: "rgb(57, 54, 239)",
    fontSize: "45px",
    textIndent: "-9999em",
    overflow: "hidden",
    width: "1em",
    height: "1em",
    borderRadius: "50%",
    position: "relative",
    transform: "translateZ(0)",
    animation: "$mltShdSpin 1.7s infinite ease, $round 1.7s infinite ease",
  },
  "@keyframes blink": {
    "0%": {
      transform: "scale(0)",
      opacity: 0,
    },
    "50%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(0)",
      opacity: 0,
    },
  },
  "@keyframes mltShdSpin": {
    "0%": {
      boxShadow:
        "0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em",
    },
    "5%, 95%": {
      boxShadow:
        "0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em",
    },
    "10%, 59%": {
      boxShadow:
        "0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em",
    },
    "20%": {
      boxShadow:
        "0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em",
    },
    "38%": {
      boxShadow:
        "0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em",
    },
    "100%": {
      boxShadow:
        "0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em",
    },
  },
  "@keyframes round": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const DotLoading = () => {
  const classes = useStyles();

  return (
    <Container className={classes.loadingContainer}>
      <div className={classes.loader}>
        <div className={classes.loader}></div>
      </div>
    </Container>
  );
};

export default DotLoading;
