import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAlert, useAxios } from "../../hooks";
import { useState } from "react";
import styled from "styled-components";
import { OuterBox, TextField } from "../../components";
import { FaUser } from "react-icons/fa";
import { useEffect } from "react";
import { signOutMember } from "../../redux/slices/memberSlice";
import pages from "../../constants/pages";
import Dependent from "./Dependent";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import icon from "./assests/Group.svg";
import ErrorIcon from "@mui/icons-material/Error";
import face from "./assests/face.svg";
import location from "./assests/location.svg";
import verify from "./assests/verify.svg";

import { IoIosArrowBack } from "react-icons/io";

const PageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  // background: linear-gradient(to bottom right, #e3f2fd, #f9fbe7);
  // padding: 20px;
  align-items: baseline !important;
`;

const ProfileContainer = styled(Card)`
 maxWidth: '800px',
  width: '100%',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  // background: 'linear-gradient(to bottom right, #e3f2fd, #f9fbe7)',
  backdropFilter: 'blur(10px)',
  marginBottom: '10vh'
`;

const HeaderButton = styled(Button)`
  // color: white;
  // background-color: red;
  // border: 1px solid red;
  // border-radius: 10px;
  // padding: 8px 16px;
  // font-weight: bold;
  // transition: all 0.3s ease;

  &:hover {
    // background-color: #ff7961;
    // border-color: #ff7961;
  }
`;

const ProfileImage = styled.img`
  width: 47%;
  height: 90%;
  border-radius: 100%;
  border: 2px solid #3936ef;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  z-index: 9999;
`;

const PlaceholderIcon = styled(FaUser)`
  width: 60%;
  height: 60%;
  border-radius: 50%;
  padding: 20px;
  color: #9e9e9e;
  background: #e0f7fa;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
`;

const InfoLabel = styled(Typography)`
  font-weight: bold;
  color: #757575;
`;

const InfoValue = styled(Typography)`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${({ isPending }) => (isPending ? "red" : "#424242")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Prevent overflowing the container */
  display: block;
  word-wrap: break-word; /* Allows text to break onto a new line if necessary */
  word-break: break-all; /* Break long words */
  padding-right: 10px; /* Add some padding to avoid text being cut off */
`;

const InfoTable = styled(Table)`
  width: 100%;
  margin-top: 20px;
  font-size: 1rem;
  text-align: left;
`;

export default function Profile() {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { visitorData } = useSelector((state) => state.memberDetails);
  const [userImage, setUserImage] = useState("");
  const [userData, setUserData] = useState({});
  const [dependentList, setDependentList] = useState(visitorData.dependentList);

  let localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return item.includes(visitorData.ID);
  });

  useEffect(() => {
    axios({
      url: "admin/profile/get-visitor-detail?visitor_id=" + visitorData.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setUserData(response.data);
      }
    });

    axios({
      url: "admin/profile/decrypt-face-data",
      method: "POST",
      disableRedirect: true,
      data: {
        face_data: localStorage.getItem(digipassDataKey[0]),
      },
    }).then((response) => {
      if (response.status) {
        setUserImage(response.data);
      }
    });

    axios({
      url:
        "event/visitor/get-dependent-list?phone_number=" +
        visitorData.phoneNumber,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setDependentList(response.data || []);
      }
    });
  }, [visitorData.ID, axios, dispatch]);

  const handleDeleteData = () => {
    axios({
      url: `admin/profile/delete-face-data?phone_number=${visitorData.phoneNumber}`,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        dispatch(signOutMember());
        localStorage.clear();
        navigate(pages.home.route);
      }
    });
  };

  const handleVerifyProfile = (ID) => {
    navigate(
      `${
        pages.Verification?.route
      }?visitor_id=${ID}&appointment_id=${-1}&officer_id=${-1}`
    );
  };

  const handleDeleteDependent = (ID) => {
    axios({
      url: `event/visitor/delete-dependent?visitor_id=${ID}`,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({ message: response.message.displayMessage, type: "success" })
        );
        axios({
          url:
            "event/visitor/get-dependent-list?phone_number=" +
            visitorData.phoneNumber,
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            setDependentList(response.data || []);
          }
        });
      }
    });
  };

  const renderInfoItem = (label, value) => (
    <TableRow>
      <TableCell sx={{ width: "40%" }}>
        <InfoLabel>{label}</InfoLabel>
      </TableCell>
      <TableCell sx={{ width: "60%" }}>
        <Tooltip title={value}>
          <InfoValue isPending={value === "pending"}>{value}</InfoValue>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  return (
    <PageWrapper>
      <ProfileContainer
        sx={{
          marginBottom: "10vh",
          background: "linear-gradient(to bottom right, #e3f2fd, #f9fbe7)",
          boxShadow: "none",
        }}
        elevation={0}
      >
        <Grid
          container
          spacing={2}
          sx={{ borderRadius: "10px", background: "white" }}
        >
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems={"center"}
            sx={{ zIndex: "999", marginTop: "20px" }}
          >
            <Grid item container className="eventImageBox">
              <div
                style={{
                  width: "100%",
                  position: "fixed",
                  overflow: "hidden",
                  top: "-8%",
                }}
              >
                <img
                  // style={{
                  //   width: "100%",
                  //   height: "35vh",
                  // }}
                  src={icon}
                />
              </div>
            </Grid>

            <Grid
              item
              container
              mb={"25px"}
              style={{ zIndex: "9999999" }}
              onClick={() => {
                console.log("5464645");
                navigate(-1);
              }}
            >
              <IoIosArrowBack
                color="#A9A9A9"
                fontSize={"24px"}
                style={{ margin: "0px 20px 0px 10px" }}
              />
              <Typography variant="body1" fontSize={"18px"} fontWeight={500}>
                Edit Profile
              </Typography>
            </Grid>

            {userImage ? (
              <ProfileImage
                src={`data:image/jpeg;base64,${userImage}`}
                alt="Profile"
              />
            ) : (
              <PlaceholderIcon />
            )}
          </Grid>

          {!userImage && (
            <Grid
              item
              container
              sx={{
                border: "0.6px solid #0000001A",
                borderRadius: "16px",
                padding: "15px !important",
                backgroundColor: "#ffd0d0",
                backgroundImage:
                  "linear-gradient(164deg, #ffd0d0 3%, #ffffff 31%)",
                margin: "20px",
              }}
            >
              <Grid item container alignItems={"center"}>
                <ErrorIcon sx={{ color: "red", marginRight: "20px" }} />
                <Typography variant="body1" fontWeight={"700"}>
                  Profile not verified yet
                </Typography>
              </Grid>
              <Grid item container m={"15px 0px"}>
                <Typography
                  variant="body1"
                  fontSize={"14px"}
                  fontWeight={"400"}
                  color={"#00000080"}
                >
                  VERIFICATION ENSURES
                </Typography>
              </Grid>

              <Grid item container>
                <img src={face} style={{ marginRight: "20px" }}></img>
                <Typography variant="body1">
                  Seamless Facial Recognition Entry
                </Typography>
              </Grid>

              <Grid item container m={"15px 0px"}>
                <img src={location} style={{ marginRight: "20px" }}></img>
                <Typography variant="body1">Faster Check-Ins</Typography>
              </Grid>

              <Grid item container>
                <img src={verify} style={{ marginRight: "20px" }}></img>
                <Typography variant="body1">
                  Enhanced Security for Your Profile
                </Typography>
              </Grid>

              <Grid item container mt={"25px"}>
                <HeaderButton
                  variant="outlined"
                  fullWidth
                  onClick={() => handleVerifyProfile(visitorData.ID)}
                >
                  Verify Profile
                </HeaderButton>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid item xs={12} padding={"5px 10px"}>
              <Typography variant="body1" fontWeight={600} mb={2}>
                Your Name
              </Typography>
              <TextField
                size="small"
                // label="Enter Your Name"
                value={visitorData.firstName}
              />
            </Grid>

            <Grid item xs={12} padding={"5px 10px"}>
              <Typography variant="body1" fontWeight={600} mb={2}>
                Gender
              </Typography>
              <TextField
                size="small"
                // label="Enter Your Name"
                value={visitorData.gender}
              />
            </Grid>

            <Grid item xs={12} padding={"5px 10px"}>
              <Typography variant="body1" fontWeight={600} mb={2}>
                Phone Number
              </Typography>
              <TextField
                size="small"
                // label="Enter Your Name"
                value={visitorData.phoneNumber}
              />
            </Grid>

            <Grid item xs={12} padding={"5px 10px"}>
              <Typography variant="body1" fontWeight={600} mb={2}>
                Email ID
              </Typography>
              <TextField
                size="small"
                // label="Enter Your Name"
                value={visitorData.emailID}
              />
            </Grid>

            {/* <InfoTable>
              <TableBody>
                {renderInfoItem("Name", visitorData.firstName || "N/A")}
                {renderInfoItem("Gender", visitorData.gender || "N/A")}
                {renderInfoItem(
                  "Phone Number",
                  visitorData.phoneNumber || "N/A"
                )}
                {renderInfoItem("Email ID", visitorData.emailID || "N/A")}
              </TableBody>
            </InfoTable> */}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "center", padding: "15px 0px" }}
            container
          >
            {userImage && (
              <HeaderButton
                variant="contained"
                // fullWidth
                sx={{ width: "60%" }}
                onClick={handleDeleteData}
              >
                Delete Your Data
              </HeaderButton>
            )}
          </Grid>

          {Object.keys(userData?.student_details || []).length > 0 && (
            <Grid
              item
              container
              sx={{
                border: "0.6px solid #0000001A",
                borderRadius: "16px",
                padding: "15px !important",
                backgroundColor: "#ffd0d0",
                backgroundImage:
                  "linear-gradient(164deg, rgb(232 232 255) 3%, rgb(255, 255, 255) 31%)",
                margin: "20px",
              }}
            >
              <Grid item container alignItems={"center"}>
                {/* <ErrorIcon sx={{ color: "red", marginRight: "20px" }} /> */}
                {/* <Typography variant="body1" fontWeight={"700"}>
                Student Pass
              </Typography> */}
              </Grid>
              <Grid item container m={"0px 0px 15px"}>
                <Typography
                  variant="body1"
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={"#00000080"}
                >
                  STUDENT PASS
                </Typography>
              </Grid>

              <Grid item container>
                {/* <img src={verify} style={{ marginRight: "20px" }}></img> */}
                <Typography variant="body1">University Name</Typography> &ensp;
                : &nbsp;{" "}
                <Typography variant="body1">
                  {userData?.student_details?.university_name}
                </Typography>
              </Grid>

              <Grid item container m={"15px 0px"}>
                {/* <img src={location} style={{ marginRight: "20px" }}></img> */}
                <Typography variant="body1">Campus Name</Typography>&ensp; :
                &nbsp;
                <Typography variant="body1">
                  {userData?.student_details?.campus_name}
                </Typography>
              </Grid>

              <Grid item container>
                {/* <img src={face} style={{ marginRight: "20px" }}></img> */}
                <Typography variant="body1">Course</Typography>&ensp; : &nbsp;
                <Typography variant="body1">
                  {" "}
                  {userData?.student_details?.course}
                </Typography>
              </Grid>

              <Grid item container mt={"25px"}>
                <HeaderButton
                  variant="outlined"
                  fullWidth
                  onClick={() => handleVerifyProfile(visitorData.ID)}
                >
                  View
                </HeaderButton>
              </Grid>
            </Grid>
          )}

          {Object.keys(userData?.employee_details || []).length > 0 && (
            <Grid
              item
              container
              sx={{
                border: "0.6px solid #0000001A",
                borderRadius: "16px",
                padding: "15px !important",
                backgroundColor: "#ffd0d0",
                backgroundImage:
                  "linear-gradient(164deg, rgb(232 232 255) 3%, rgb(255, 255, 255) 31%)",
                margin: "20px",
              }}
            >
              <Grid item container alignItems={"center"}>
                {/* <ErrorIcon sx={{ color: "red", marginRight: "20px" }} /> */}
                {/* <Typography variant="body1" fontWeight={"700"}>
                Student Pass
              </Typography> */}
              </Grid>
              <Grid item container m={"0px 0px 15px"}>
                <Typography
                  variant="body1"
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={"#00000080"}
                >
                  EMPLOYEE PASS
                </Typography>
              </Grid>

              <Grid item container>
                {/* <img src={verify} style={{ marginRight: "20px" }}></img> */}
                <Typography variant="body1">University Name</Typography> &ensp;
                : &nbsp;{" "}
                <Typography variant="body1">
                  {userData?.employee_details?.university_name}
                </Typography>
              </Grid>

              <Grid item container m={"15px 0px"}>
                {/* <img src={location} style={{ marginRight: "20px" }}></img> */}
                <Typography variant="body1">Designation</Typography>&ensp; :
                &nbsp;
                <Typography variant="body1">
                  {userData?.employee_details?.designation}
                </Typography>
              </Grid>

              <Grid item container>
                {/* <img src={face} style={{ marginRight: "20px" }}></img> */}
                <Typography variant="body1">Employee ID</Typography>&ensp; :
                &nbsp;
                <Typography variant="body1">
                  {" "}
                  {userData?.employee_details?.employee_id}
                </Typography>
              </Grid>

              <Grid item container mt={"25px"}>
                <HeaderButton
                  variant="outlined"
                  fullWidth
                  onClick={() => handleVerifyProfile(visitorData.ID)}
                >
                  View
                </HeaderButton>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          spacing={2}
          padding={4}
          sx={{ background: "white", marginTop: "15px" }}
        >
          <Dependent setDependentList={setDependentList} />
        </Grid>

        {dependentList?.map((item, index) => {
          return (
            <Grid
              container
              spacing={2}
              padding={4}
              sx={{ background: "white", marginTop: "15px" }}
            >
              <Grid item>
                <Grid item container>
                  <Typography variant="h6" fontWeight={"900"}>
                    Dependent : {index + 1}
                    {"  "}
                  </Typography>
                  {item.is_verified && (
                    <Tooltip title="Verified Profile">
                      <VerifiedIcon sx={{ color: "green" }} />
                    </Tooltip>
                  )}
                </Grid>

                {renderInfoItem("Name", item.first_name + item.last_name)}
                {renderInfoItem("Gender", item.gender)}
                {renderInfoItem("Phone Number", item.phone_number)}
                {renderInfoItem("Email ID", item.email_id)}
              </Grid>
              <Grid item container justifyContent={"space-around"}>
                <Grid item xs={item.is_verified ? 8 : 5}>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      background: "red",
                      color: "white",
                      borderRadius: "12.5px",
                      border: "1px solid red",
                      "&:hover": {
                        backgroundColor: "white",
                        borderRadius: "12.5px",
                        border: "1px solid red",
                        color: "red",
                      },
                    }}
                    onClick={() => handleDeleteDependent(item._id)}
                  >
                    Delete
                  </Button>
                </Grid>
                <Grid item xs={5} display={item.is_verified && "none"}>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      background: "red",
                      color: "white",
                      borderRadius: "12.5px",
                      border: "1px solid red",
                      "&:hover": {
                        backgroundColor: "white",
                        borderRadius: "12.5px",
                        color: "red",
                        border: "1px solid red",
                      },
                    }}
                    onClick={() => handleVerifyProfile(item._id)}
                  >
                    Verify
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </ProfileContainer>
    </PageWrapper>
  );
}
