import { Fragment, useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  // Link,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Label, Paper, TextField } from "./../../components";
import { useNavigate } from "react-router-dom";
import LOGO from ".././../layouts/application layout components/assets/logo.png";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Fragment>
      <Grid item xs={12}>
        {/* <Grid
          item
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignContent: "center",
          }}
          xs={12}
        >
          <img
            className="logoImage"
            src={LOGO}
            style={{ marginTop: "10px", width: "85px" }}
          />
        </Grid> */}

        {/* <Typography variant="h2" align="center" sx={{ color: "#006e00" }}>
          digipass
        </Typography> */}

        <Typography variant="h5" fontWeight={600} align="center">
          SIGN IN
        </Typography>
      </Grid>

      <Grid item xs={12} pt={"3px !important"}>
        <Label required title="Email" formikProps={formikProps} />
      </Grid>

      <Grid item xs={12} pt={"0px !important"}>
        <TextField
          required
          name="email"
          label="ex. john001@verismart.ai"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12} pt={"3px !important"}>
        <Label required title="Password" formikProps={formikProps} />
      </Grid>

      <Grid item xs={12} pt={"0px !important"}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="password"
          label="password here"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/* <Grid item xs={12} pt={"3px !important"}>
        <Label required title="Agent Name" formikProps={formikProps} />
      </Grid>

      <Grid item xs={12} pt={"0px !important"}>
        <TextField
          required
          name="agent_name"
          label="agent name"
          formikProps={formikProps}
          style={{ borderRadius: "15px !important" }}
        />
      </Grid> */}

      {/* <Grid item> */}
      {/* <FormControlLabel
          control={
            <Checkbox name="rememberMe" onChange={formikProps.handleChange} />
          }
          label="Remember me"
        /> */}
      {/* </Grid> */}

      <Grid item xs />

      {/* <Grid item alignSelf="center">
        <Link href="/forgot-password">Forgot Password?</Link>
      </Grid> */}

      <Grid item xs={12} mt={5}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          // disabled={!formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Sign In
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        {/* <Typography variant="caption1">
          Don't have account?{" "}
          <Typography
            variant="button"
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sign-up")}
          >
            Sign Up!
          </Typography>
        </Typography> */}
      </Grid>
    </Fragment>
  );
}
