import { Button, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { Fragment, useState } from "react";
import styled from "styled-components";
import TextField from "./../../components/TextField";
import FileUpload from "./../../components/FileUpload";
import SubheaderSection from "./../../components/SubheaderSection";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 60vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

export default function AddHotelBooking() {
  const [formData, setFormData] = useState({
    ota: "",
    bookingNumber: "",
    otaPDF: "",
  });
  return (
    <>
      <OuterBox>
        <OuterPaper>
          <Grid item xs={12}>
            <SubheaderSection
              textAlign="start"
              title="Add Hotel Booking"
              content="Enter your OTA "
            />
          </Grid>
          <Formik initialValues={formData}>
            {(formikProps) => {
              return (
                <Fragment>
                  <Grid item xs={12}>
                    <TextField
                      name="ota"
                      label="OTA"
                      formikProps={formikProps}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} m={"25px 0px"}>
                    <TextField
                      name="bookingNumber"
                      label="Booking Number"
                      formikProps={formikProps}
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} mb={"25px"}>
                    <FileUpload
                      name="otaPDF"
                      label="Upload OTA PDF"
                      formikProps={formikProps}
                      allowedExtension=".pdf"
                      folderPath={
                        "mpsedc/digipass/events" + new Date().getTime() + "_"
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button variant="contained" fullWidth>
                      Submit
                    </Button>
                  </Grid>
                </Fragment>
              );
            }}
          </Formik>
        </OuterPaper>
      </OuterBox>
    </>
  );
}
