import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  MenuItem,
  Button,
} from "@mui/material";

import { SubheaderSection, TextField } from "../../components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useParams } from "react-router-dom";
import { useAxios } from "../../hooks";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Fragment } from "react";
import { Formik } from "formik";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    background: "rgb(255, 255, 255)",
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
}));

const ContentBox = styled(Box)({
  padding: "30px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
});

const ParkingDetialsPopup = ({
  eventID,
  bookingId,
  openParkingDetial,
  setOpenParkingDetial,
}) => {
  const axios = useAxios();

  const [parkingList, setParkingList] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);

  const [vehicleCount, setVehicleCount] = useState(1);
  useEffect(() => {
    axios({
      url: "/admin/parking/vehicle-details?event_id=" + eventID,
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      disableRedirect: true,
      disableError: true,
    }).then((response) => {
      if (response.status) {
        setVehicleDetails(response.data);
      }
    });

    if (bookingId) {
      axios({
        url: "/admin/parking/user-parking-details?booking_id=" + bookingId,
        disableRedirect: true,
        disableError: true,
      }).then((response) => {
        if (response.status) {
          setParkingList(response.data || []);
        }
      });
    }
  }, [bookingId]);

  return (
    <StyledDialog
      open={openParkingDetial}
      onClose={() => setOpenParkingDetial(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ padding: "0px" }}>
        <ContentBox>
          <SubheaderSection
            textAlign="center"
            variant="h4"
            title={`Parking Detials`}
            titleColor="#2b2b2b"
          />
          {/*  */}
          {parkingList.map((item, index) => {
            return (
              <Grid
                item
                container
                spacing={3}
                m={"2px 2px"}
                sx={{
                  boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
                  padding: "10px 10px 10px 0px !important",
                  borderRadius: "12.5px",

                  border: "1px solid rgb(232, 222, 207)",
                  position: "relative",
                }}
              >
                <Grid item xs={5}>
                  <TextField label="Vehicle Type" value={item.vehicle_type} />
                </Grid>

                <Grid item xs={5}>
                  <TextField label="Vehicle No" value={item.vehicle_number} />
                </Grid>
              </Grid>
            );
          })}

          <Formik
            initialValues={{ bookingId: bookingId, vehicleDetails: [] }}
            onSubmit={(formData, { setSubmitting }) => {
              axios({
                url: "/admin/parking/parking-details?is_parking=true",
                method: "POST",
                data: {
                  booking_id: formData.bookingId,
                  vehicle_details: formData.vehicleDetails.slice(
                    0,
                    formData.vehicleCount
                  ),
                },
                disableRedirect: true,
              }).then((response) => {
                if (response.status) {
                  setOpenParkingDetial(false);
                }
              });
            }}
          >
            {(formikProps) => {
              return (
                <Fragment>
                  {Array(vehicleCount)
                    .fill(true)
                    .map((item, index) => {
                      return (
                        <Fragment>
                          <Grid
                            item
                            container
                            spacing={3}
                            m={"2px 2px"}
                            sx={{
                              boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
                              padding: "10px 10px 10px 0px !important",
                              borderRadius: "12.5px",

                              border: "1px solid rgb(232, 222, 207)",
                              position: "relative",
                            }}
                          >
                            <Grid item xs={5}>
                              <TextField
                                select
                                formikProps={formikProps}
                                label="Choose Vehicle Type"
                                name={`vehicleDetails[${index}].vehicle_type`}
                                value={
                                  formikProps.values[
                                    `vehicleDetails[${index}].vehicle_type`
                                  ]
                                }
                              >
                                {vehicleDetails.map((item) => {
                                  return (
                                    <MenuItem value={item.vehicle_type}>
                                      {item.vehicle_type}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>

                            <Grid item xs={5}>
                              <TextField
                                name={`vehicleDetails[${index}].vehicle_number`}
                                label={"Vehicle No"}
                                formikProps={formikProps}
                              />
                            </Grid>

                            <Grid item xs={2} alignContent={"center"}>
                              {index == 0 ? (
                                <AddCircleIcon
                                  onClick={() => {
                                    formikProps.setFieldValue(
                                      "vehicleCount",
                                      vehicleCount + 1
                                    );
                                    setVehicleCount((prev) => prev + 1);
                                  }}
                                />
                              ) : (
                                <AiOutlineDelete
                                  style={{
                                    color: "white",
                                    padding: "3px",
                                    fontSize: "27.5px",
                                    backgroundColor: "#ff9191",
                                    backgroundImage:
                                      "linear-gradient(164deg, #ff9191 0%, #FF2525 74%)",
                                    borderRadius: "5px",

                                    boxShadow: "1px 2px 4px 1px #bbbaba",
                                  }}
                                  onClick={() => {
                                    formikProps.setFieldValue(
                                      "vehicleCount",
                                      vehicleCount - 1
                                    );

                                    setVehicleCount((prev) => prev - 1);
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Fragment>
                      );
                    })}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      m={"2px 2px"}
                      style={{ borderRadius: "4px", marginTop: "5px" }}
                      onClick={formikProps.handleSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Fragment>
              );
            }}
          </Formik>
        </ContentBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default ParkingDetialsPopup;
