import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";

import { Loader } from "../../components";
import pages from "../../constants/pages";
import { useComponent } from "../../hooks";
import useAxios from "./../../hooks/useAxios";
import convertDateDayMonth from "../../utils/convertDateDayMonth";
import { useSelector } from "react-redux";
import EventSlider from "./EventSlider";
import EventSlider2 from "./EventSlider2";

import Music from "./assests/music.svg";
import Travel from "./assests/Travel.svg";
import Comedy from "./assests/Comedy.svg";
import Tech from "./assests/tech.svg";
import Arrow from "./assests/arrow.svg";
import Food from "./assests/Food.svg";
import Business from "./assests/Business.svg";
import Kids from "./assests/Kids.svg";
import Hobbies from "./assests/hobbies.svg";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const CategoryItem = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: baseline;
  text-align: center;
  // border: 2px solid #e0f2f8;
  border-radius: 12px;
  transition: all 0.1s ease;
  background: white;
  // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  // margin: 0px auto;
  padding: 0px 7px;

  box-shadow: 0px 2px 3px 1px #00000014;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 768px) {
    width: 140px;
    height: 85px;
  }

  @media screen and (max-width: 480px) {
    width: 30%;
    height: 90px;
  }
`;

const CategoryGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 25px;
  // padding: 0px 35px;
`;

const CategoryIcon = styled.img`
  font-size: 35px;
  color: ${(props) => props.color || "#0073e6"};
  // margin-bottom: 10px;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

const EventList = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [eventList, setEventList] = useState([]);

  const categories = [
    {
      name: "Music",
      icon: Music,
      // color: "#edbc00",
      route: pages.eventListPage.route,
    },
    {
      name: "Business",
      icon: Business,
      // color: "#0073e6",
      route: visitorData.ID
        ? pages.AppointmentBooking.route
        : `${pages.appointment.route}bookappointment`,
    },
    {
      name: "Food & Drink",
      icon: Food,
      // color: "#28a745",
      route: pages.eventListPage.route,
    },
    {
      name: "Comedy",
      icon: Comedy,
      // color: "#dc3545",
      route: pages.HotelBookingPage.route,
    },

    {
      name: "Kids",
      icon: Kids,
      // color: "#dc3545",
      route: pages.HotelBookingPage.route,
    },
    {
      name: "Hobbies",
      icon: Hobbies,
      // color: "#dc3545",
      route: pages.HotelBookingPage.route,
    },
    {
      name: "Science & Tech",
      icon: Tech,
      // color: "#dc3545",
      route: pages.HotelBookingPage.route,
    },
    {
      name: "Travel & Outdoors",
      icon: Travel,
      // color: "#dc3545",
      route: pages.HotelBookingPage.route,
    },
    {
      name: "View All Categories",
      icon: Arrow,
      // color: "#dc3545",
      route: pages.HotelBookingPage.route,
    },
  ];

  useEffect(() => {
    loader.start();
    axios({ url: "admin/events/open-events", disableRedirect: true }).then(
      (res) => {
        res.status && setEventList(res.data);
        loader.apiComplete();
      }
    );
  }, [axios]);

  const handleEvent = (id) => {
    if (!visitorData.ID) {
      navigate(`${pages.appointment.route}${id}`);
    } else {
      axios({
        url:
          "event/visitor/check-booking?event_id=" +
          id +
          "&mobile_number=91" +
          visitorData.phoneNumber,
        disableRedirect: true,
        disableAlert: false,
      })
        .then((response) => {
          if (response.status) {
            if (response.data.length > 0) {
              if (response.data[0].payment_status === "Pending") {
                navigate(
                  pages.EventBooking.route +
                    "/" +
                    response.data[0]?.event_id +
                    "/" +
                    response.data[0]?._id,
                  {
                    state: {
                      userData: response.data,
                    },
                  }
                );
              }

              if (response.data[0].payment_status === "Completed") {
                navigate(
                  pages.EventSummary.route +
                    response.data[0].event_id +
                    "/" +
                    response.data[0]._id,
                  {
                    state: {
                      userData: response.data,
                    },
                  }
                );
              }
            }

            if (response.data[0]?.payment_status === "Completed") {
              navigate(
                pages.EventSummary.route +
                  response.data[0].event_id +
                  "/" +
                  response.data[0]._id,
                {
                  state: {
                    userData: response.data,
                  },
                }
              );
            } else {
              navigate(
                pages.EventBooking.route + "/" + id + "/" + visitorData.ID
              );
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  return (
    <Loader>
      <OuterBox>
        <OuterPaper>
          <EventSlider bannerList={eventList} backgroundColor="#FFF" />

          <Typography
            textAlign="start"
            fontWeight={600}
            variant="h3"
            mb={3}
            mt={4}
            // sx={{ "-webkit-text-stroke": "1px black" }}
          >
            Trending Events
          </Typography>

          <Grid
            item
            container
            justifyContent="center"
            flexDirection={"column"}
            sx={{ overflow: "hidden" }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{
                alignContent: "center",
                justifyContent: "space-between",
                textAlign: { xs: "center", sm: "start" },
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  borderRadius: "12.5px",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  flexDirection: "row",
                  padding: "10px 5px !important",
                }}
                xs={12}
              >
                {eventList.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      justifyContent={"center"}
                      onClick={() => {
                        navigate(
                          pages.EventPage.path + "?event_id=" + item.event_id
                        );
                      }}
                    >
                      <Grid item>
                        <img
                          src={item?.banner_image}
                          className="QrCodeImage"
                          width={"225px"}
                          height={"300px"}
                          style={{ margin: "0px 20px", borderRadius: "12px" }}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        p={"5px 0px"}
                        sx={{ width: "225px" }}
                      >
                        <Grid
                          item
                          xs={4}
                          sx={{
                            border: "1px solid #E9E9E9",
                            borderRadius: "6px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              background: "#FAF7FF",
                              borderTopLeftRadius: "6px",
                              borderTopRightRadius: "6px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontSize={"12px"}
                              fontWeight={500}
                              color={"#5C5C5C"}
                            >
                              {item.day || "Sat"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              fontSize={"22px"}
                              fontWeight={700}
                            >
                              {item?.date || 17}
                            </Typography>
                            <Typography
                              variant="body1"
                              fontSize={"13px"}
                              fontWeight={400}
                            >
                              {item.month || "Dec"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={8}
                          textAlign={"start"}
                          paddingLeft={"10px"}
                        >
                          <Typography
                            variant="body1"
                            fontSize={"15px"}
                            fontWeight={700}
                          >
                            {" "}
                            {item.event_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize={"13px"}
                            fontWeight={500}
                            color={"#5C5C5C"}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.address.full_address}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize={"13px"}
                            fontWeight={500}
                            color={"#5C5C5C"}
                            mt={1}
                          >
                            ₹{item.ticket_cost} onwards
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            <Typography
              variant="h4"
              fontWeight="900"
              align="start"
              gutterBottom
            >
              Browse by Category
            </Typography>

            <CategoryGrid>
              {categories.map((category, index) => {
                return categories.length - 4 >= index ? (
                  <CategoryItem
                    key={index}
                    onClick={() => navigate(category.route)}
                  >
                    <CategoryIcon src={category.icon}></CategoryIcon>
                    <Typography
                      variant="h6"
                      fontSize={"14px"}
                      fontWeight="400"
                      sx={{ margin: "0px 10px", textAlign: "start" }}
                    >
                      {category.name}
                    </Typography>
                  </CategoryItem>
                ) : (
                  <CategoryItem
                    key={index}
                    style={{
                      height: "110px",
                      background: index === categories.length - 1 && "#3936EF",
                    }}
                    onClick={() => navigate(category.route)}
                  >
                    <CategoryIcon src={category.icon}></CategoryIcon>
                    <Typography
                      variant="h6"
                      fontSize={"14px"}
                      fontWeight="400"
                      sx={{
                        margin: "0px 10px",
                        textAlign: "start",
                        color: index === categories.length - 1 && "white",
                      }}
                    >
                      {category.name}
                    </Typography>
                  </CategoryItem>
                );
              })}
            </CategoryGrid>
          </Grid>

          <Grid
            item
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Grid item>
              <Typography
                textAlign="start"
                fontWeight={600}
                variant="h3"
                fontSize={"24px"}
                mb={3}
                mt={4}
              >
                Music
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                textAlign="start"
                fontWeight={600}
                variant="h3"
                fontSize={"14px"}
                mb={3}
                mt={4}
              >
                View All
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            flexDirection={"column"}
            sx={{ overflow: "hidden" }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{
                alignContent: "center",
                justifyContent: "space-between",
                textAlign: { xs: "center", sm: "start" },
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  borderRadius: "12.5px",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  flexDirection: "row",
                  padding: "10px 5px !important",
                }}
                xs={12}
              >
                {eventList.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      onClick={() => {
                        navigate(
                          pages.EventPage.path + "?event_id=" + item.event_id
                        );
                      }}
                    >
                      <Grid item>
                        <img
                          src={item?.banner_image}
                          className="QrCodeImage"
                          width={"298px"}
                          height={"158px"}
                          style={{ margin: "0px 20px", borderRadius: "8px" }}
                        />
                      </Grid>
                      <Grid item container p={"5px 20px"}>
                        <Grid
                          item
                          xs={2.75}
                          sx={{
                            border: "1px solid #E9E9E9",
                            borderRadius: "6px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              background: "#FAF7FF",
                              borderTopLeftRadius: "6px",
                              borderTopRightRadius: "6px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontSize={"12px"}
                              fontWeight={500}
                              color={"#5C5C5C"}
                            >
                              {item.weekDay || "Sat"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              fontSize={"22px"}
                              fontWeight={700}
                            >
                              {item?.day || 17}
                            </Typography>
                            <Typography
                              variant="body1"
                              fontSize={"13px"}
                              fontWeight={400}
                            >
                              {item.month || "Dec"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={9.25}
                          textAlign={"start"}
                          paddingLeft={"10px"}
                        >
                          <Typography
                            variant="body1"
                            fontSize={"15px"}
                            fontWeight={700}
                          >
                            {item.event_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize={"13px"}
                            fontWeight={500}
                            color={"#5C5C5C"}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.address.full_address}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize={"13px"}
                            fontWeight={500}
                            color={"#5C5C5C"}
                          >
                            ₹{item.ticket_cost} onwards
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </OuterPaper>
      </OuterBox>
    </Loader>
  );
};

export default EventList;
