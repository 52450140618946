import * as Yup from "yup";
import { Formik } from "formik";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Label, OuterBox, SubheaderSection, TextField } from "../../components";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import UserDetails from "./UserDetails";
import { useAxios } from "../../hooks";
import { setDisplayName } from "../../redux/slices/memberSlice";

const ManualEntry = () => {
  const axios = useAxios({
    baseURL: "mox_api_2",
  });

  const dispatch = useDispatch();

  const { authToken, displayName, eventID } = useSelector(
    (state) => state.memberDetails
  );

  const [guardDetails, setGuardDetails] = useState({
    name: displayName || "",
    gateNo: 0,
    QRCode: "",
    ImgData: "",
    reason: "",
    userDetails: undefined,
    isAllowed: true,
  });

  const [loading, setLoading] = useState(false);

  const [userStep, setUserStep] = useState("");

  const [gates, setGates] = useState([]);

  const [agentName, setAgentName] = useState(displayName || "");

  const [selectedGate, setSelectedGate] = useState(
    Number(sessionStorage.getItem("gateMate")) || 0
  );

  const [entryStatus, setEntryStatus] = useState(undefined);
  const [faceVerificationStatus, setFaceVerificationStatus] =
    useState(undefined);
  const [appointmentStatus, setAppointmentStatus] = useState(undefined);
  const [mainEntryStatus, setMainEntryStatus] = useState(undefined);
  const [bookingExistStatus, setBookingExistStatus] = useState(undefined);

  useEffect(() => {
    if (Boolean(displayName)) {
      setUserStep("QRcode");
    }

    axios({
      url: "/visitor/dome-list",
      disableRedirect: true,
      disableError: true,
    }).then((response) => {
      if (response.status) {
        setGates(response.data);
      }
    });
  }, []);

  function fetchUserDetails(formikProps, id, eventID) {
    setLoading(true);

    let url =
      Number(sessionStorage.getItem("gateMate")) === 1
        ? "/booking/verify-visitor"
        : "booking/verify/dome-visitor";

    axios({
      url: url,
      method: "POST",
      customAuthToken: authToken,
      data: {
        agent_name: formikProps.values.name,
        is_manual: true,
        machine_id:
          "" + Number(sessionStorage.getItem("gateMate")) || selectedGate,
        face_data: "",
        id: id || eventID,
        booking_id: id || eventID,
        event_id: id ? eventID : sessionStorage.getItem("eventID"),
      },
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setUserStep("userDetails");

        formikProps.setValues((prevValues) => ({
          ...prevValues,
          userDetails: response.data,
        }));
      } else {
        setAppointmentStatus(response.data.appointment_status);
        setFaceVerificationStatus(response.data?.face_verification);
        setMainEntryStatus(response.data?.main_entry);
        setBookingExistStatus(response.data?.booking_exist);
      }
      setLoading(false);
    });
  }

  return (
    <OuterBox>
      {!entryStatus &&
        entryStatus === undefined &&
        faceVerificationStatus === undefined &&
        appointmentStatus === undefined && (
          <Formik
            initialValues={guardDetails}
            validationSchema={validationSchema}
            onSubmit={(formData, { setSubmitting }) => {
              setSubmitting(true);
              // console.log(formData, "formData");

              let url = "/booking/update-checkin";

              axios({
                url: url,
                method: "POST",
                data: {
                  checkin_id: formData.userDetails?.checkin_id,
                  agent_name: formData.name,
                  is_allowed: formData.isAllowed,
                  reason: formData.reason,
                },
                customAuthToken: authToken,
                disableRedirect: true,
              }).then((response) => {
                if (response.status) {
                  setEntryStatus(response.status);
                } else {
                  setEntryStatus(response.status);
                  setAppointmentStatus(response.data?.appointment_status);
                  setFaceVerificationStatus(response.data?.face_verification);
                }
                setSubmitting(false);
              });
            }}
          >
            {(formikProps) => {
              return (
                <Fragment>
                  {agentName && userStep !== "" && (
                    <Grid container justifyContent={"end"}>
                      <Typography alignContent={"center"}>
                        Welcome
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {agentName}
                        </span>{" "}
                      </Typography>

                      <Tooltip title="Edit Name">
                        <EditRoundedIcon
                          sx={{
                            fontSize: "30px",
                            borderRadius: "15px",
                            padding: "5px",
                            "&:hover": { background: "rgb(108 108 108 / 87%)" },
                          }}
                          onClick={() => {
                            formikProps.setValues(() => ({
                              name: "",
                              gateNo: 0,
                              QRCode: "",
                              ImgData: "",
                              userDetails: undefined,
                              isAllowed: false,
                            }));
                            setSelectedGate(0);
                            setUserStep("");
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  )}

                  {userStep === "" && (
                    <Grid container>
                      <Grid item xs={4} alignSelf={"center"}>
                        <Label
                          formikProps={formikProps}
                          title={"Enter Your Name"}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField name="name" formikProps={formikProps} />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          variant="contained"
                          sx={{ height: "100%" }}
                          disabled={!formikProps.dirty}
                          onClick={() => {
                            setAgentName(formikProps.values.name);
                            dispatch(setDisplayName(formikProps.values.name));
                            setUserStep("QRcode");
                          }}
                        >
                          <ArrowForwardIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {userStep === "QRcode" && (
                    <Grid container>
                      {!Boolean(formikProps.values.userDetails) && (
                        <Fragment>
                          <Grid item xs={12}>
                            <Typography variant="h5" textAlign={"center"}>
                              First Select Gate No
                            </Typography>
                          </Grid>

                          <Grid item container m={3} justifyContent={"center"}>
                            <Grid item xs={4}>
                              <TextField
                                select
                                name="gateNo"
                                formikProps={formikProps}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setSelectedGate(value);

                                  sessionStorage.setItem(
                                    "gateMate",
                                    e.target.value
                                  );

                                  formikProps.setValues((prev) => {
                                    return { ...prev, gateNo: value };
                                  });
                                }}
                                value={selectedGate}
                              >
                                <MenuItem value={0}>
                                  Select Your Current Gate
                                </MenuItem>
                                {gates.map((item) => {
                                  return (
                                    <MenuItem value={item._id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}

                      {Boolean(
                        selectedGate !== 0 &&
                          !formikProps.values.userDetails &&
                          !appointmentStatus &&
                          !loading
                      ) && (
                        <Fragment>
                          <Grid item container justifyContent={"center"}>
                            <Typography variant="h5" sx={{ fontWeight: 600 }}>
                              Scan QR Code{" "}
                            </Typography>
                          </Grid>
                          <Grid item container justifyContent={"center"}>
                            <Grid item sx={{ width: "50vh" }}>
                              <QrReader
                                style={{ width: "100%" }}
                                onResult={(result, error) => {
                                  if (!!result) {
                                    let [eventID, bookingID] =
                                      result?.text.split("_");

                                    fetchUserDetails(
                                      formikProps,
                                      bookingID,
                                      eventID
                                    );
                                  }

                                  if (!!error) {
                                    console.info(error);
                                  }
                                }}
                                constraints={{
                                  facingMode: "environment",
                                }}
                                scanDelay={250}
                              />
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}

                      {loading && (
                        <Fragment>
                          <Grid
                            container
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            m={15}
                          >
                            <CircularProgress sx={{ color: "green" }} />
                            <Typography
                              variant="h6"
                              sx={{ mt: "30px" }}
                              fontWeight={"600"}
                            >
                              Wait Getting User Details
                            </Typography>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  )}
                  {Boolean(formikProps.values.userDetails) &&
                    userStep === "userDetails" && (
                      <UserDetails formikProps={formikProps} />
                    )}
                </Fragment>
              );
            }}
          </Formik>
        )}

      {entryStatus && (
        <Grid
          container
          display={"flex"}
          sx={{ flexDirection: "column" }}
          alignItems="center"
          justifyContent={"center"}
        >
          <CheckCircleOutlineRoundedIcon
            sx={{
              fontSize: "80px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
              marginBottom: "30px",
            }}
          />
          <audio
            autoPlay
            src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227404.694377_aws.wav"
            // loop
          >
            {" "}
          </audio>
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgb(41 128 31)",
            }}
          >
            User Access Granted
          </Typography>
          <Button
            variant="outlined"
            sx={{ marginTop: "30px" }}
            onClick={() => {
              setUserStep("QRcode");
              setEntryStatus(undefined);
              setAppointmentStatus(undefined);
              setFaceVerificationStatus(undefined);
              setMainEntryStatus(undefined);
              setBookingExistStatus(undefined);
            }}
          >
            Take Next Entry
          </Button>
        </Grid>
      )}

      {entryStatus === false && (
        <Grid
          container
          display={"flex"}
          sx={{ flexDirection: "column" }}
          alignItems="center"
          justifyContent={"center"}
        >
          <CancelRoundedIcon
            sx={{
              fontSize: "80px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
              marginBottom: "30px",
            }}
          />
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
            }}
          >
            User Access Denied
          </Typography>
          <audio
            autoPlay
            src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227737.921351_aws.wav"
            // loop
          >
            {" "}
          </audio>
          <Button
            variant="outlined"
            sx={{ marginTop: "30px", color: "red", border: "1px solid red" }}
            onClick={() => {
              setUserStep("QRcode");
              setEntryStatus(undefined);
              setAppointmentStatus(undefined);
              setFaceVerificationStatus(undefined);
              setMainEntryStatus(undefined);
              setBookingExistStatus(undefined);
            }}
          >
            Take Next Entry
          </Button>
        </Grid>
      )}

      {bookingExistStatus !== undefined && bookingExistStatus === false && (
        <Grid
          container
          display={"flex"}
          sx={{ flexDirection: "column" }}
          alignItems="center"
          justifyContent={"center"}
        >
          <CancelRoundedIcon
            sx={{
              fontSize: "80px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
              marginBottom: "30px",
            }}
          />
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
            }}
          >
            Booking not found
          </Typography>
          <audio
            autoPlay
            src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227737.921351_aws.wav"
            // loop
          >
            {" "}
          </audio>{" "}
          <Button
            variant="outlined"
            sx={{ marginTop: "30px", color: "red", border: "1px solid red" }}
            onClick={() => {
              setFaceVerificationStatus(undefined);
              setAppointmentStatus(undefined);
              setMainEntryStatus(undefined);
              setBookingExistStatus(undefined);
            }}
          >
            Try Again
          </Button>
        </Grid>
      )}

      {appointmentStatus !== undefined &&
        appointmentStatus === false &&
        faceVerificationStatus !== false &&
        bookingExistStatus === true && (
          <Grid
            container
            display={"flex"}
            sx={{ flexDirection: "column" }}
            alignItems="center"
            justifyContent={"center"}
          >
            <CancelRoundedIcon
              sx={{
                fontSize: "80px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
                marginBottom: "30px",
              }}
            />
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
              }}
            >
              Does not have access to this room.
            </Typography>
            <audio
              autoPlay
              src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227737.921351_aws.wav"
              // loop
            >
              {" "}
            </audio>
            <Button
              variant="outlined"
              sx={{
                marginTop: "30px",
                color: "red",
                border: "1px solid red",
              }}
              onClick={() => {
                setFaceVerificationStatus(undefined);
                setAppointmentStatus(undefined);
                setMainEntryStatus(undefined);
                setBookingExistStatus(undefined);
              }}
            >
              Try Again
            </Button>
          </Grid>
        )}

      {mainEntryStatus !== undefined &&
        bookingExistStatus === true &&
        appointmentStatus === true &&
        faceVerificationStatus !== false &&
        mainEntryStatus === false && (
          <Grid
            container
            display={"flex"}
            sx={{ flexDirection: "column" }}
            alignItems="center"
            justifyContent={"center"}
          >
            <CancelRoundedIcon
              sx={{
                fontSize: "80px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
                marginBottom: "30px",
              }}
            />
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
              }}
            >
              Please verify at the main gate first
            </Typography>
            <audio
              autoPlay
              src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227737.921351_aws.wav"
              // loop
            >
              {" "}
            </audio>
            <Button
              variant="outlined"
              sx={{ marginTop: "30px", color: "red", border: "1px solid red" }}
              onClick={() => {
                setFaceVerificationStatus(undefined);
                setAppointmentStatus(undefined);
                setMainEntryStatus(undefined);
                setBookingExistStatus(undefined);
              }}
            >
              Try Again
            </Button>
          </Grid>
        )}

      {faceVerificationStatus !== undefined &&
        appointmentStatus !== false &&
        faceVerificationStatus === false && (
          <Grid
            container
            display={"flex"}
            sx={{ flexDirection: "column" }}
            alignItems="center"
            justifyContent={"center"}
          >
            <CancelRoundedIcon
              sx={{
                fontSize: "80px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
                marginBottom: "30px",
              }}
            />
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "red",
              }}
            >
              User Didn't Complete Face verification
            </Typography>
            <audio
              autoPlay
              src="https://broadcast-contact-numbers.s3.ap-south-1.amazonaws.com/output_1719227737.921351_aws.wav"
              // loop
            >
              {" "}
            </audio>
            <Button
              variant="outlined"
              sx={{ marginTop: "30px", color: "red", border: "1px solid red" }}
              onClick={() => {
                setAppointmentStatus(undefined);
                setFaceVerificationStatus(undefined);
                setMainEntryStatus(undefined);
                setBookingExistStatus(undefined);
              }}
            >
              Take Entry Next
            </Button>
          </Grid>
        )}
    </OuterBox>
  );
};

const validationSchema = Yup.object().shape({
  reason: Yup.string().when("isAllowed", {
    is: (val) => val === false,
    then: Yup.string().required("Reason is required"),
    otherwise: Yup.string(),
  }),
});

export default ManualEntry;
