import React from "react";

import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

//import { setDLData, setAadharData } from "../../redux/slices/documentSlice";
import VideoPreview from "./Preview";

export default function PreviewVideo() {
  const userData = useLocation();
  console.log(userData, "userData");

  return (
    <React.Fragment>
      <VideoPreview userData={userData} />
    </React.Fragment>
  );
}
