import React, { Fragment, useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Container, Grid, Paper, Typography, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigateBeforeIcon from "@mui/icons-material/WestSharp";
import NavigateNextIcon from "@mui/icons-material/EastSharp";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks";
import { useSelector } from "react-redux";
import pages from "../../constants/pages";
const CardContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
  borderRadius: "20px",
}));

const TitlePaper = styled(Paper)({
  backgroundColor: "#107de2",
  color: "#fff",
  borderRadius: "22px",
  width: "114px",
  height: "36px",
  fontSize: "1rem",
  padding: "8px",
  position: "absolute",
  top: "-2ex",
  zIndex: "1",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: "500",
});

const HeadingPaper = styled(Paper)({
  backgroundColor: "#107de2",
  textAlign: "center",
  width: "100%",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 500,
  color: "#fff",
  margin: "0px",
  padding: "15px 15px",
  fontSize: "24px",
});

const useStyles = makeStyles((theme) => ({
  FAQHeading: {
    margin: "10px",
    padding: "20px",
    fontSize: "36px",
    color: "#474747",
    fontWeight: 300,
    fontFamily: '"Montserrat", sans-serif',
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // width: "50px",
    color: "#107de2",
    fontSize: "45px",
  },
  swiperWrapper: {
    display: "flex",
    flex: "1",
    width: "80vw",
    alignItems: "baseline",
  },
}));

const SubHeadingPaper = styled(Typography)({
  textAlign: "left",
  width: "100%",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 500,
  color: "#474747",
  fontSize: "12px",
  padding: "15px 15px 10px 15px",
});

const CardSlider2 = ({ backgroundColor, cardData, heading }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const axios = useAxios();
  const { visitorData } = useSelector((state) => state.memberDetails);
  const swiperRef = useRef(null);
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => {
      if (windowWidth <= 600) {
        setIsMobile(true);
        setSlidesPerView(1);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleEvent = (id) => {
    if (!visitorData.ID) {
      navigate(`${pages.appointment.route}${id}`);
    } else {
      axios({
        url:
          "event/visitor/check-booking?event_id=" +
          id +
          "&mobile_number=91" +
          visitorData.phoneNumber,
        disableRedirect: true,
        disableAlert: false,
      })
        .then((response) => {
          if (response.status) {
            if (response.data.length > 0) {
              if (response.data[0].payment_status === "Pending") {
                navigate(
                  pages.EventBooking.route +
                    "/" +
                    response.data[0]?.event_id +
                    "/" +
                    response.data[0]?._id,
                  {
                    state: {
                      userData: response.data,
                    },
                  }
                );
              }

              if (response.data[0].payment_status === "Completed") {
                navigate(
                  pages.EventSummary.route +
                    response.data[0].event_id +
                    "/" +
                    response.data[0]._id,
                  {
                    state: {
                      userData: response.data,
                    },
                  }
                );
              }
            }

            if (response.data[0]?.payment_status === "Completed") {
              navigate(
                pages.EventSummary.route +
                  response.data[0].event_id +
                  "/" +
                  response.data[0]._id,
                {
                  state: {
                    userData: response.data,
                  },
                }
              );
            } else {
              navigate(
                pages.EventBooking.route + "/" + id + "/" + visitorData.ID
              );
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  return (
    <Fragment>
      <Grid
        container
        sx={{
          padding: "15px 0px",
          // backgroundColor: backgroundColor,
          // borderBottom: "1px solid #d6d6d6",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className={classes.swiperWrapper}>
          <Swiper
            ref={swiperRef}
            slidesPerView={slidesPerView}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {cardData.map((card, index) => (
              <SwiperSlide
                key={index}
                style={{
                  width: "100%",
                  height: windowWidth <= 1024 ? "170px" : "310px",
                }}
              >
                <CardContainer
                  sx={{ position: "relative" }}
                  onClick={() => handleEvent(card.event_id)}
                >
                  <Paper
                    sx={{
                      // border: "1px solid rgb(179, 115, 0)",
                      // boxShadow: "2px 5px 5px 3px #a2a2a2",
                      borderRadius: "7.5px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      height: "300px",
                      width: "225px",
                    }}
                  >
                    <img
                      src={card.banner_image || card.imageSrc}
                      alt={card.event_name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "7.5px",
                      }}
                    />
                  </Paper>
                </CardContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Grid>
    </Fragment>
  );
};

export default CardSlider2;
