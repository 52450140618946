import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import useAxios from "./../../hooks/useAxios";
import {
  Box,
  Grid,
  Typography,
  Divider,
  MenuItem,
  Tooltip,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import styled from "styled-components";
import convertDate from "./../../utils/convertDate";
import { useNavigate } from "react-router-dom";
import pages from "./../../constants/pages";
import { TextField } from "../../components";
import Filter from "./Filter";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const OuterContainer = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 20px auto;
  padding: 0px 16px 10vh 16px;
  // background: #f4f4f4;
  border-radius: 10px;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Tab = styled.div`
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  background: ${(props) => (props.active ? "#1976d2" : "#ffffff")};
  color: ${(props) => (props.active ? "#ffffff" : "#1976d2")};
  border: 2px solid #1976d2;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${(props) => (props.active ? "#1565c0" : "#e3f2fd")};
  }
`;

const RecordCard = styled(Box)`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
`;

const StatusIndicator = styled(Typography)`
  font-weight: bold;
  color: ${(props) =>
    props.status === "pending"
      ? "#d32f2f"
      : props.status === "completed"
      ? "#388e3c"
      : "#616161"};
`;

const DateHeader = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: #424242;
  margin-bottom: 8px;
`;

export default function Transaction() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [transaction, setTransaction] = useState([]);
  const [filterTransaction, setFilterTransaction] = useState([]);
  const [selectOption, setSelectOption] = useState("");
  const [dependentList, setDependentList] = useState([]);
  const [dependentID, setDependentID] = useState("");

  const [dailogOpen, setDailogOpen] = useState(false);

  useEffect(() => {
    axios({
      url:
        "/admin/profile/get-user-bookings?phone_number=" +
        visitorData.phoneNumber,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTransaction(response.data);
        setFilterTransaction(response.data);
      }
    });

    axios({
      url:
        "event/visitor/get-dependent-list?phone_number=" +
        visitorData.phoneNumber,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setDependentList(response.data || []);
      }
    });
  }, []);

  function handleDependentID(ID) {
    setDependentID(ID);

    axios({
      url:
        "/admin/profile/get-user-bookings?" +
        (ID === "phone_number"
          ? "phone_number=" + visitorData.phoneNumber
          : "visitor_id=" + ID),
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTransaction(response.data);
        setFilterTransaction(response.data);
      }
    });
  }

  function handleFilter() {
    setDailogOpen(true);
  }

  return (
    <Fragment>
      <OuterContainer
        sx={{ height: filterTransaction.length <= 3 ? "81vh" : "auto" }}
      >
        <Grid container spacing={2} justifyContent="center">
          {/* <Grid item alignContent={"center"} xs={6}>
            <Typography variant="h6">Select Dependent &#8594;</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              label="Select Dependent"
              value={dependentID}
              onChange={(e) => handleDependentID(e.target.value)}
            >
              <MenuItem value={"phone_number"}>
                {visitorData.firstName + " " + visitorData.lastName}
              </MenuItem>
              {dependentList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item._id}>
                    {item.first_name + " " + item.last_name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid> */}

          {/* <Grid item xs={6}>
            <Tab
              active={selectOption === "appointment"}
              onClick={() => {
                setFilterTransaction(
                  transaction.filter((item) => item.booking_type == 2)
                );
                setSelectOption("appointment");
              }}
            >
              Appointments
            </Tab>
          </Grid>
          <Grid item xs={6}>
            <Tab
              active={selectOption === "event"}
              onClick={() => {
                setFilterTransaction(
                  transaction.filter((item) => item.booking_type == 1)
                );
                setSelectOption("event");
              }}
            >
              Events
            </Tab>
          </Grid> */}
        </Grid>

        <Grid item container>
          <Typography
            variant="h3"
            fontWeight={900}
            sx={{
              marginBottom: "15px",
              // border: "1px solid black",
              borderRadius: "8px",
              padding: "10px",
              color: "rgb(104, 102, 244)",
            }}
          >
            Regular Pass
          </Typography>

          <Grid item xs={12}>
            {transaction.map((item, index) => (
              <Fragment>
                {item.booking_type === 3 && (
                  <Fragment key={index}>
                    <DateHeader>
                      {convertDate(item?.appointment_date)}
                    </DateHeader>
                    <RecordCard>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={8}>
                          <Typography variant="body1" fontWeight="bold">
                            {item?.campus_name} {`( ${item?.course} )`}{" "}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Zone: {item?.zone}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                          <StatusIndicator status={item?.appointment_status}>
                            <CheckCircleIcon sx={{ color: "green" }} />
                          </StatusIndicator>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Typography
                        variant="body2"
                        textAlign="right"
                        color="textSecondary"
                      >
                        Start at : {convertDate(item?.registered_at)}
                      </Typography>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        color="textSecondary"
                      >
                        End at : {convertDate(item?.end_at)}
                      </Typography>
                    </RecordCard>
                  </Fragment>
                )}
              </Fragment>
            ))}
          </Grid>

          <Divider
            sx={{
              width: "100%",
              background: "rgb(85, 83, 235)",
              margin: "25px 0px 5px 0px",
            }}
          />

          <Grid
            item
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant="h3"
              fontWeight={900}
              sx={{
                // border: "1px solid black",
                borderRadius: "8px",
                padding: "10px",
                color: "rgb(104, 102, 244)",
              }}
            >
              Events Pass
            </Typography>

            <Tooltip title="filter">
              <FilterAltIcon
                sx={{ color: "rgb(104, 102, 244)" }}
                onClick={handleFilter}
              />
            </Tooltip>
          </Grid>

          <Grid item container justifyContent="center" spacing={3} mb={3}>
            <Grid item alignContent={"center"} xs={6}>
              <Typography variant="h6">Select Dependent &#8594;</Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                label="Select Dependent"
                value={dependentID}
                onChange={(e) => handleDependentID(e.target.value)}
              >
                <MenuItem value={"phone_number"}>
                  {visitorData.firstName + " " + visitorData.lastName}
                </MenuItem>
                {dependentList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item._id}>
                      {item.first_name + " " + item.last_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            {/* <Grid item xs={6}>
              <Tab
                active={selectOption === "appointment"}
                onClick={() => {
                  setFilterTransaction(
                    transaction.filter((item) => item.booking_type == 2)
                  );
                  setSelectOption("appointment");
                }}
              >
                Appointments
              </Tab>
            </Grid>
            <Grid item xs={6}>
              <Tab
                active={selectOption === "event"}
                onClick={() => {
                  setFilterTransaction(
                    transaction.filter((item) => item.booking_type == 1)
                  );
                  setSelectOption("event");
                }}
              >
                Events
              </Tab>
            </Grid> */}
          </Grid>

          <Grid item xs={12}>
            {filterTransaction.map((item, index) => (
              <Fragment>
                {item.booking_type === 2 ? (
                  <Fragment key={index}>
                    <DateHeader>
                      {convertDate(item?.appointment_date)}
                    </DateHeader>
                    <RecordCard>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={8}>
                          <Typography variant="body1" fontWeight="bold">
                            Officer: {item?.officer_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Time: {item?.appointment_time}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                          <StatusIndicator status={item?.appointment_status}>
                            {item?.appointment_status.toUpperCase()}
                          </StatusIndicator>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Typography
                        variant="body2"
                        textAlign="right"
                        color="textSecondary"
                      >
                        Created: {convertDate(item?.created_at)}
                      </Typography>
                    </RecordCard>
                  </Fragment>
                ) : (
                  <Fragment key={index}>
                    <DateHeader>{convertDate(item?.created_at)}</DateHeader>
                    <RecordCard>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        onClick={() =>
                          navigate(
                            `${pages.EventSummary?.route}${item.event_id}/${item.visitor_id}`
                          )
                        }
                      >
                        <Grid item xs={8}>
                          <Typography variant="body1" fontWeight="bold">
                            Event: {item?.event_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Address: {item?.event_address}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                          <Typography
                            variant="body1"
                            color={
                              item?.payment_status !== "Completed"
                                ? "error"
                                : "success"
                            }
                          >
                            &#8377; {item?.total_amount}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Typography
                        variant="body2"
                        textAlign="right"
                        color="textSecondary"
                      >
                        Booking ID: {item?.booking_id}
                      </Typography>
                    </RecordCard>
                  </Fragment>
                )}
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </OuterContainer>

      <Filter
        dailogOpen={dailogOpen}
        setDailogOpen={setDailogOpen}
        setFilterTransaction={setFilterTransaction}
        setSelectOption={setSelectOption}
        transaction={transaction}
      />
    </Fragment>
  );
}
