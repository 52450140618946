import axios from "axios";

import BASE_URL from "./../../../constants/baseURL";

export default async function signIn({
  authToken,
  agentName,
  eventID,
  visitorData,
  businessEntityId,
}) {
  localStorage.setItem("authToken", authToken);

  const axiosInstance = axios.create({
    baseURL: BASE_URL.mox_api.url + BASE_URL.mox_api.version,
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    },
  });

  let dependentList;
  try {
    dependentList = await axiosInstance({
      url: `event/visitor/get-dependent-list?phone_number=${visitorData.phone_number}`,
      method: "GET",
    }).then((res) => res.data.data);
  } catch (error) {
    console.log("error", error);
  }

  return {
    authToken: authToken,
    displayName: agentName || "",
    eventID: eventID,
    visitorData: {
      ID: visitorData?._id,
      lastName: visitorData?.last_name,
      gender: visitorData?.gender,
      emailID: visitorData?.email_id,
      faceData: visitorData?.face_data,
      firstName: visitorData?.first_name,
      onWhatsapp: visitorData?.on_whatsapp,
      phoneNumber: visitorData?.phone_number,
      dependentList: dependentList,
    },
  };
}
