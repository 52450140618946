import React from "react";
import { TextField } from "@mui/material";
import styled from "styled-components";

const TextFieldWithCSS = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 0.6px solid rgba(0, 0, 0, 0.1);

    // border-top: none !important;
    // border-left: none !important;
    // border-right: none !important;
  }
`;

const OTPInput = ({
  value,
  onChange,
  onKeyDown,
  index,
  inputRefs,
  otpLength,
}) => {
  return (
    <TextFieldWithCSS
      inputRef={(el) => (inputRefs.current[index] = el)}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyPress={(e) => {
        if (!/^[0-9]$/.test(e.key)) {
          e.preventDefault();
        }
      }}
      style={{
        width: "50px",
        background: "#FFFFFF",
        borderRadius: "8px",
      }}
      inputProps={{
        type: "tel",
        maxLength: 1,
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      }}
    />
  );
};

export default OTPInput;
