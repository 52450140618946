import React from "react";
import { Button } from "@mui/material";

const SubmitButton = ({
  onClick,
  text,
  backgroundColor,
  float = "right",
  ...props
}) => (
  <Button
    {...props}
    variant="contained"
    color="primary"
    sx={{
      fontSize: "16px",
      float: float,
      fontWeight: 500,
      fontStyle: "normal",
      backgroundColor: "#107DE2", // Keep the background color consistent
      marginTop: "16px",
      marginBottom: "16px",
      color: "#FFF",
      borderRadius: 0,
      width: "100%", // Set width to 100% for mobile screens
      "@media (min-width: 600px)": {
        // Add a media query for larger screens if needed
        width: "auto", // Reset width to its default value for larger screens
      },
      "&:hover": {
        backgroundColor: "#107DE2", // Keep the background color consistent
        borderRadius: 0,
      },
    }}
  >
    {text}
  </Button>
);

export default SubmitButton;
