import { Button, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import pages from "../../constants/pages";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

export default function HotelBooking() {
  const navigate = useNavigate();
  return (
    <>
      <OuterBox>
        <OuterPaper>
          <Grid item container justifyContent={"end"}>
            <Button
              variant="contained"
              onClick={() => navigate(pages.AddHotelBookingPage.path)}
            >
              Add Booking
            </Button>
          </Grid>
          <Grid item container></Grid>
        </OuterPaper>
      </OuterBox>
    </>
  );
}
