import { Grid } from "@mui/material";
import { Fragment } from "react";
import { TiTicket } from "react-icons/ti";

export default function SummaryTable({
  formikProps,
  eventObject,
  vehicleDetails,
  badgeOptions,
}) {
  return (
    <Grid item container>
      <table
        style={{
          width: "100%",
          border: "1px solid #0000001A",
          padding: "10px",
          borderRadius: "5px",
          position: "relative",
          // boxShadow: "3px 3px 5px -2px rgb(232 196 144)",
        }}
      >
        <span
          class="__circle-left"
          style={{
            display: "inline-block",
            position: "absolute",
            top: "45%",
            zIndex: "1",
            width: "22px",
            height: "22px",
            background: "#ffffff",
            borderRadius: "50%",
            left: "-11px",
            boxShadow: "inset -2px 0 0 #0000001A",
          }}
        ></span>
        <span
          class="__circle-right"
          style={{
            display: "inline-block",
            position: "absolute",
            top: "40%",
            zIndex: "1",
            width: "22px",
            height: "22px",
            background: "#ffffff",
            borderRadius: "50%",
            right: "-11px",
            boxShadow: "inset 2px 0 0 #0000001A",
          }}
        ></span>
        <thead>
          <tr>
            <td
              style={{
                fontSize: "large",
                fontWeight: "600",
                color: "black",
              }}
            >
              Event Ticket
            </td>
            <td
              style={{
                fontSize: "large",
                fontWeight: "600",
                color: "black",
              }}
            ></td>
          </tr>
        </thead>

        <tbody>
          {Boolean(formikProps.values.eventAmount) && (
            <tr>
              <td>
                <TiTicket style={{ fontSize: "19px", verticalAlign: "top" }} />{" "}
                {formikProps.values.memberCount + 1}&nbsp; &#10006; &nbsp;
                {eventObject?.event_name}&nbsp; - &nbsp; ₹
                {formikProps.values.eventAmount}
              </td>
              <td style={{ textAlign: "end" }}>
                ₹
                {formikProps.values.eventAmount *
                  (formikProps.values.memberCount + 1)}
              </td>
            </tr>
          )}

          {Array(formikProps.values.memberCount + 1)
            .fill(true)
            .map((item, index) => {
              return (
                <Fragment>
                  {formikProps.values.visitorData[index]?.badge_id && (
                    <tr>
                      <td>
                        <TiTicket
                          style={{
                            fontSize: "19px",
                            verticalAlign: "top",
                          }}
                        />{" "}
                        1&nbsp; &#10006; &nbsp;{" "}
                        {
                          badgeOptions.filter((item) => {
                            return (
                              item._id ==
                              formikProps.values.visitorData[index]?.badge_id
                            );
                          })[0]?.badge_name
                        }{" "}
                        &nbsp;- &nbsp;₹{" "}
                        {formikProps.values.visitorData[index]?.badge_id_amount}
                      </td>
                      <td style={{ textAlign: "end" }}>
                        ₹{" "}
                        {formikProps.values.visitorData[index]?.badge_id_amount}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}

          {formikProps.values?.vehicle?.map((item, index) => {
            return (
              <Fragment>
                {formikProps.values.vehicle.length && (
                  <tr>
                    <td>
                      <TiTicket
                        style={{
                          fontSize: "19px",
                          verticalAlign: "top",
                        }}
                      />{" "}
                      1&nbsp; &#10006; &nbsp; {item.vehicle_type} &nbsp;-
                      &nbsp;₹{" "}
                      {
                        vehicleDetails.filter((ite, index) => {
                          return ite.vehicle_type == item?.vehicle_type;
                        })[0]?.amount
                      }
                    </td>

                    <td style={{ textAlign: "end" }}>
                      ₹{" "}
                      {
                        vehicleDetails.filter((ite, index) => {
                          return ite.vehicle_type == item?.vehicle_type;
                        })[0]?.amount
                      }
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}

          <tr>
            <td
              style={{
                fontSize: "large",
                fontWeight: "700",
                color: "black",
              }}
            >
              Total
            </td>

            <td
              style={{
                fontSize: "large",
                fontWeight: "700",
                color: "black",
                textAlign: "end",
              }}
            >
              ₹{" "}
              {parseInt(
                formikProps.values.eventAmount *
                  formikProps.values.visitorData?.length
              ) +
                formikProps.values.vehicle?.reduce((sum, current, index) => {
                  return (
                    (parseInt(
                      vehicleDetails.filter((ite, index) => {
                        return ite.vehicle_type == current?.vehicle_type;
                      })[0]?.amount
                    ) || 0) + sum
                  );
                }, 0) +
                formikProps.values.visitorData?.reduce(
                  (sum, current, index) => {
                    return (current?.badge_id_amount || 0) + sum;
                  },
                  0
                ) || 0}
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  );
}
