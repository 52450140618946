import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import { useAlert } from "../../hooks";
import { LoadingButton } from "./../../components";
import TextField from "./../../components/TextField";

const MobileNumberInput = ({
  loading,
  onSubmit,
  setMobileNumber,
  mobileNumber,
  handleUserConsentChange,
  handleGoBack,
}) => {
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const handleMobileNumberChange = (event) => {
    const inputNumber = event.target.value;

    // Remove any non-digit characters from the input
    const cleanedInput = inputNumber.replace(/\D/g, "");

    // Check if the cleaned input starts with 6, 7, 8, or 9
    if (/^[6-9]/.test(cleanedInput) || cleanedInput === "") {
      setMobileNumber(cleanedInput);
    }
  };

  const handleGetOTP = () => {
    if (mobileNumber.length !== 10 || !/^[0-9]+$/.test(mobileNumber)) {
      // console.log(mobileNumber);
      dispatch(
        alert({
          type: "error",
          message: "Please enter a valid 10-digit mobile number.",
        })
      );
      return;
    }
    sessionStorage.setItem("mobileNumber", mobileNumber);
    onSubmit(mobileNumber);
  };

  return (
    <form style={{ width: "100%" }}>
      <TextField
        label="Enter Your 10 Digit Mobile Number"
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Enter Your 10 Digit Mobile Number"
        inputProps={{
          type: "tel",
          maxLength: 10,
          pattern: "[6-9]\\d*", // Pattern to match numbers starting with 6, 7, 8, or 9
        }}
        size="small"
        style={{ margin: "30px 0px 15px 0px" }}
        value={mobileNumber}
        onChange={handleMobileNumberChange}
      />

      <Grid
        item
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <Grid item xs={12}>
          <LoadingButton
            loading={loading}
            onClick={handleGetOTP}
            style={{ padding: "10px 12.5px", fontWeight: 700 }}
          >
            Get OTP
          </LoadingButton>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "rgba(0, 0, 0, 0.1)",
                "&.Mui-checked": {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              }}
            />
          }
          label="This Number is available on Whatsapp"
          onClick={handleUserConsentChange}
          style={{ fontWeight: "900 !important" }}
        />

        {/* <Grid item xs={12}>
          <LoadingButton
            onClick={handleGoBack}
            backgroundColor="#F5F0E5"
            style={{ padding: "10px 14.5px", color: "black", fontWeight: 700 }}
          >
            Back
          </LoadingButton>
        </Grid> */}
      </Grid>
    </form>
  );
};

export default MobileNumberInput;
