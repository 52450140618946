import { MdVerified } from "react-icons/md";
import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { IoIosInformationCircle } from "react-icons/io";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ImagePopup from "./ImagePopup";
import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import convertDate from "../../utils/convertDate";
import LoadingButton from "../../components/LoadingButton";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

const TableCells = styled(TableCell)``;

export default function AppointmentSummary() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const { eventID } = useParams();

  const [open, setOpen] = useState(false);
  const queryParams = new URLSearchParams(search);
  const [userData, setUserData] = useState(state);

  const officerId = queryParams.get("officer_id");

  const appointmentId = queryParams.get("appointment_id");

  const userPayloadString = localStorage.getItem("userPayload");
  const userPayload = JSON.parse(userPayloadString);

  useEffect(() => {
    if (!Boolean(userData?.match_percentage && userPayload?.doc_type)) {
      let url = "appointment/appointment/check-appointment";

      let method = "POST";

      let data = {
        officer_id: officerId,
        appointment_id: appointmentId,
      };

      axios({
        url: url,
        disableRedirect: true,
        method: method,
        data: data,
      }).then((response) => {
        if (response.status) {
          setUserData({ userData: response.data.appointment_data });
        }
      });
    }
  }, [axios, eventID]);

  const getButtonText = (item) => {
    if (item?.journey_status?.payment_status === "Pending")
      return "Payment Pending";
    if (item?.journey_status?.is_verified === false)
      return "Verification Pending";
    if (item?.journey_status?.send_consent === true) return "Consent Pending";
    // if (item?.journey_status?.consent === false) return "Consent Pending";
    return false;
  };

  const handleClick = (item) => {
    const { journey_status, event_id, visitor_id, booking_id } = item;
    if (journey_status?.payment_status === "Pending") {
      navigate(`${pages.EventBooking.route}/${event_id}/${visitor_id}`);
    } else if (journey_status?.is_verified === false) {
      navigate(
        `${pages.Verification.route}?visitor_id=${visitor_id}&booking_id=${booking_id}&event_id=${event_id}`
      );
    } else if (journey_status?.send_consent === true) {
      navigate(
        `${pages.Consent.route}?booking_id=${booking_id}&visitorId=${visitor_id}`
      );
    }
  };

  const renderInfoItem = (label, value) => (
    <TableRow>
      <TableCells sx={{ width: "40%" }}>
        <Typography
          variant="body1"
          style={{ color: "rgb(150 150 150)" }}
          fontWeight={900}
        >
          {label}
        </Typography>
      </TableCells>
      <TableCells sx={{ width: "60%", textAlign: "center" }}>
        <Tooltip title={value}>
          <Typography
            variant="h6"
            fontWeight={900}
            sx={{
              color: value === "pending" ? "red" : "black",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value.toUpperCase()}
          </Typography>
        </Tooltip>
      </TableCells>
    </TableRow>
  );

  return (
    <OuterBox>
      {userData?.userData.map((item, index) => {
        return (
          <OuterPaper
            sx={{
              padding: { xs: "30px 0px 0px 0px", sm: "30px 80px 0px 80px" },
            }}
          >
            <Grid
              container
              flexDirection="column"
              padding={{ xs: "0px", sm: "0px", md: "0px" }}
              sx={{ height: "100%" }}
            >
              <Grid
                item
                container
                sx={{
                  height: "100%",
                  position: "relative",
                  borderRadius: "12.5px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} padding="20px">
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bolder",
                      "-webkit-text-stroke-width": ".5px",
                    }}
                  >
                    Appointment Summary
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="displayCenter"
                  sx={{
                    border: " 1px solid rgb(232, 222, 207)",
                    borderRadius: "12.5px",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    flexDirection: "row",
                    padding: "10px 5px !important",
                    alignItems: "center",
                  }}
                  xs={6}
                >
                  <img
                    src={item?.qr_link}
                    className="QrCodeImage"
                    style={{ margin: "0px 20px", height: "80px" }}
                  />
                </Grid>

                <Grid item sx={{ width: "100%" }} padding="30px">
                  <Grid
                    item
                    className="displayCenter"
                    sx={{ overflow: "hidden" }}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{
                        alignContent: "center",
                        justifyContent: "space-between",
                        textAlign: { xs: "center", sm: "start" },
                      }}
                    >
                      <Grid item xs={12}>
                        <Table>
                          <TableBody>
                            {renderInfoItem("Name", item?.first_name)}
                            {renderInfoItem("Phone Number", item?.phone_number)}
                            {renderInfoItem("Email ID", item?.email_id)}
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sx={{ alignSelf: "center", justifyContent: "center" }}
                      >
                        {getButtonText(item) && (
                          <LoadingButton
                            onClick={() => handleClick(item)}
                            variant="contained"
                            style={{ width: "70%" }}
                          >
                            {getButtonText(item)}
                          </LoadingButton>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: { xs: "center", sm: "start" },
                      }}
                    >
                      <Table>
                        <TableBody>
                          {renderInfoItem(
                            "Appointment ID",
                            item?.appointment_id
                          )}
                          {renderInfoItem(
                            "Registration Date",
                            convertDate(item?.created_at)
                          )}
                          {console.log(
                            item?.appointment_status,
                            "                            item?.appointment_status"
                          )}
                          {renderInfoItem(
                            "Appointment Status",
                            item?.appointment_status
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </OuterPaper>
        );
      })}

      {Boolean(userData?.match_percentage && userPayload?.doc_type) && (
        <Grid
          container
          pt={"10px"}
          sx={{
            width: "100%",
            padding: { xs: "30px 0px 0px 0px", sm: "30px 80px 0px 80px" },
          }}
        >
          <Grid
            item
            container
            sx={{
              boxShadow: "rgb(0 0 0 / 53%) 0px 3px 15px",
              padding: "8.5px",
              borderRadius: "12.5px",
              height: "100px",
            }}
          >
            <Grid item xs={4} sx={{ position: "relative" }}>
              <img
                width="100%"
                height="80px"
                style={{ objectFit: "initial" }}
                src={"data:image/png;base64," + userPayload.doc_face_image}
                // src={userData.face_data}
                alt="Preview"
                style={{ borderRadius: "8px" }}
              />

              <MdVerified
                style={{
                  color: "green",
                  bottom: "10px",
                  right: "5px",
                  fontSize: "25px",
                  position: "absolute",
                  borderRadius: "15px",
                  background: "white",
                }}
              />
            </Grid>
            <Grid
              item
              xs={8}
              className="displayCenter"
              sx={{
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  padding: "10px",
                  fontWeight: "bold",
                  "-webkit-text-stroke": ".5px",
                }}
              >
                Verification Done{"  "}
                <IoIosInformationCircle
                  style={{
                    color: "blue",
                    fontSize: "20px",
                    verticalAlign: "middle",
                  }}
                  onClick={() => setOpen(true)}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      <ImagePopup
        open={open}
        setOpen={setOpen}
        state={userData}
        userPayload={userPayload}
      />
    </OuterBox>
  );
}
