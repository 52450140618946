// import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import React, { useRef, useEffect } from "react";
import LoadingButton from "./../../components/LoadingButton";

function ImgCapture({
  loading,
  videoRef,
  cameraScreen,
  bioFormikProps,
  onSubmit,
}) {
  // const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const uploadedFiles = useRef([]);

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
        audio: false,
      });
      videoRef.current.srcObject = stream;
    } catch (error) {
      if (error.name === "NotReadableError") {
        console.log(
          "NotReadableError: The camera might be in use or not available"
        );
        alert(
          "Unable to access the camera. Please ensure it's not in use by another application and refresh the page."
        );
        window.location.reload();
      } else if (error.name === "OverconstrainedError") {
        console.log(
          "OverconstrainedError: The constraints don't match any installed camera"
        );
        alert("No suitable camera found. Please check your camera settings.");
      } else {
        alert(`Error accessing camera: ${error.message}`);
      }
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      canvas.width = video.videoWidth * 0.5;
      canvas.height = video.videoHeight * 0.5;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageDataURL = canvas.toDataURL("image/png");

      bioFormikProps.setFieldValue(
        "ImgData",
        imageDataURL.replace(/^data:image\/[a-z]+;base64,/, "")
      );

      stopCamera();

      if (onSubmit) {
        onSubmit();
      }
    }
  };

  useEffect(() => {
    startCamera();

    return () => {
      stopCamera();
    };
  }, [cameraScreen]);

  useEffect(() => {
    const handleOrientation = () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (video && canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      }
    };

    window.addEventListener("resize", handleOrientation);
    window.addEventListener("orientationchange", handleOrientation);

    return () => {
      window.removeEventListener("resize", handleOrientation);
      window.removeEventListener("orientationchange", handleOrientation);
    };
  }, []);
  return (
    <Grid item container display={"flex"} justifyContent={"center"}>
      <Grid item container justifyContent={"center"}>
        {/* <Typography textAlign={"center"} fontWeight={"bold"}>
          Click Picture
        </Typography> */}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          left: "20%",
          width: "40vh",
          height: "auto",
          m: 5,
          "@media (max-width: 1200px)": {
            left: "10%",
            width: "40vh",
          },
          "@media (max-width: 900px)": {
            left: "5%",
            width: "40vh",
          },
          "@media (max-width: 550px)": {
            left: "0%",
            width: "100%", // Full width for very small screens
            m: 2, // Reduce margin for very small screens
          },
        }}
      >
        <video
          id="video"
          style={{ width: "100%", height: "100%" }} // Make the video fill its container
          ref={videoRef}
          muted
          autoPlay
          playsInline
        ></video>
      </Grid>
      <Grid item xs={12} justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          id="click-photo"
          loading={bioFormikProps.isSubmitting}
          type="button"
          onClick={takePhoto}
        >
          Click Picture
        </LoadingButton>
      </Grid>
      <canvas
        id="canvas"
        width="320"
        height="240"
        ref={canvasRef}
        style={{ display: "none" }}
      ></canvas>
    </Grid>
  );
}

export default ImgCapture;
