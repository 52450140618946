import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useRef } from "react";
import ErrorIcon from "@mui/icons-material/Error";

import {
  LoadingButton,
  ResendOTPButton,
  OTPInput,
  Loader,
} from "./../../components";

const OTPVerification = ({
  loading,
  otpLength,
  onSubmit,
  onResend,
  otp,
  setOTP,
  otpMatch,
  handleOtpGoBack,
  setOTPMatch,
}) => {
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    setOTPMatch(true);
    if (value !== "" && index < otpLength - 1) {
      newOTP[index + 1] = "";
      setOTP(newOTP);
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      inputRefs.current[index - 1].focus();
    }
    setOTPMatch(true);
  };

  useEffect(() => {
    if (otp.filter((item) => item == "").length == 0) {
      onSubmit();
    }
  }, [otp]);

  return (
    <Fragment>
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "35px",
          }}
        >
          {otp.map((value, index) => (
            <OTPInput
              key={index}
              index={index}
              value={value}
              otp={otp}
              inputRefs={inputRefs}
              otpLength={otpLength}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          ))}
        </div>

        <Grid item container justifyContent={"start"}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            {/* <LoadingButton
              loading={loading}
              onClick={onSubmit}
              style={{ padding: "10px 12.5px", fontWeight: 700 }}
            >
              Verify OTP
            </LoadingButton> */}

            {/* <LoadingButton
            onClick={handleOtpGoBack}
            backgroundColor="#F5F0E5"
            style={{ padding: "10px 14.5px", color: "black", fontWeight: 700 }}
          >
            Back
          </LoadingButton> */}
            <Typography
              variant="body2"
              sx={{ paddingRight: "6px", display: !loading && "none" }}
            >
              <Fragment>
                <CircularProgress
                  size={"15px"}
                  color={"inherit"}
                  sx={{ marginRight: "10.5px" }}
                />
                Verifying OTP
              </Fragment>
            </Typography>

            <Typography
              variant="body2"
              sx={{ paddingRight: "6px", color: "red" }}
            >
              {!loading && !otpMatch && (
                <Fragment>
                  <ErrorIcon
                    color={"error"}
                    sx={{
                      marginRight: "2.5px",
                      fontSize: "15px",
                      verticalAlign: "bottom",
                    }}
                  />
                  Oops, the OTP you’ve entered is incorrect. Please try again.
                </Fragment>
              )}
            </Typography>
          </Grid>
        </Grid>
      </form>

      {/* <ResendOTPButton onClick={onResend} disabled={loading} /> */}
    </Fragment>
  );
};

export default OTPVerification;
