import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Autorenew as ResendIcon } from "@mui/icons-material";

const ResendOTPButton = ({ onClick, disabled }) => {
  const [countdown, setCountdown] = useState(0);
  const [disabledResendOTP, setDisabledResendOTP] = useState(false);

  const handleResendOTP = () => {
    setDisabledResendOTP(true);
    setCountdown(60);

    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setDisabledResendOTP(false);
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item container xs={12} mb={2}>
        <Typography variant="body2" sx={{ paddingRight: "6px" }}>
          {disabledResendOTP
            ? `Resend OTP in (${countdown}s)`
            : "Didn’t Receive OTP yet? Resend again."}
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <Button
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "10px 12.5px",
          }}
          onClick={() => {
            onClick();
            handleResendOTP();
          }}
          fullWidth
          // startIcon={<ResendIcon />}
          disabled={disabledResendOTP || disabled}
        >
          Get via SMS
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResendOTPButton;
