// VisitorForm.js
import React, { Fragment } from "react";
import { Grid, Typography, MenuItem, Tooltip, Button } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { TextField } from "../../components";

const VisitorForm = ({
  index,
  formikProps,
  dependentList,
  visitorData,
  handleAddDependent,
  handleSaveDependent,
  badgeOptions,
  handleUpdateBadge,
  visitorValid,
}) => {
  return (
    <Grid item container>
      <Fragment>
        <Grid
          container
          spacing={2}
          sx={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "8px",
            margin: "10px 0px",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "0px",
            }}
          >
            <Typography variant="body1" fontWeight={700}>
              Person {index + 1}
            </Typography>

            {formikProps.values?.visitors[index]?.name && (
              <Fragment>
                {formikProps.values?.visitors[index]?.is_verified ||
                (dependentList[index - 1]?.is_verified &&
                  formikProps.values?.visitors[index].dependent != 0) ? (
                  <Tooltip title="Verified">
                    <VerifiedIcon sx={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Not Verified User">
                    <NewReleasesIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </Fragment>
            )}
          </Grid>

          {index !== 0 && (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={700}>
                Select Guest Type
              </Typography>

              <TextField
                select
                size="small"
                name={`visitors[${index}].guestType`}
                placeholder="Select Guest Type"
                formikProps={formikProps}
              >
                <MenuItem value={0}>New User</MenuItem>
                <MenuItem value={1}>Dependent</MenuItem>
              </TextField>
            </Grid>
          )}

          <Grid item xs={12}>
            {visitorData?.dependentList &&
              formikProps.values?.visitors[index]?.guestType === 1 &&
              index !== 0 && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <TextField
                    select
                    size="small"
                    name={`visitors[${index}].dependent`}
                    placeholder="Select Dependent"
                    label="Select Dependent"
                    formikProps={formikProps}
                    onChange={(e) => {
                      const value = e.target.value;
                      const dependent = dependentList.find(
                        (item) => item._id === value
                      );

                      formikProps.setFieldValue(
                        `visitors[${index}]`,
                        value === 0
                          ? {
                              dependent: 0,
                              is_dependent: true,
                              name: "",
                              email_id: "",
                              phone_number: visitorData.phoneNumber,
                              is_verified: false,
                            }
                          : {
                              ...dependent,
                              dependent: value,
                              is_dependent: true,
                            }
                      );
                    }}
                  >
                    <MenuItem value={0}>Add New Dependent</MenuItem>
                    {dependentList.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.first_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1">Enter Name</Typography>
            <TextField
              name={`visitors[${index}].name`}
              placeholder="Enter Name"
              size="small"
              formikProps={formikProps}
              disabled={formikProps.values?.edit}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1">Enter Email</Typography>
            <TextField
              size="small"
              name={`visitors[${index}].email_id`}
              placeholder="Enter Email"
              formikProps={formikProps}
              disabled={formikProps.values?.edit}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1">Enter Phone Number</Typography>
            <TextField
              size="small"
              name={`visitors[${index}].phone_number`}
              placeholder="Enter Phone Number"
              formikProps={formikProps}
              disabled={formikProps.values?.edit}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1">Select Badge</Typography>
            <TextField
              select
              size="small"
              name={`visitors[${index}].badge_id`}
              placeholder="Select Badge"
              formikProps={formikProps}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `visitors[${index}].badge_id`,
                  e.target.value
                );
                if (index === 0) handleUpdateBadge(e.target.value);
              }}
            >
              {badgeOptions.map((item, index) => (
                <MenuItem key={index} value={item.badge_id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {index !== 0 &&
            visitorValid[index] &&
            formikProps.values?.visitors[index]?.guestType === 1 && (
              <Grid item xs={12}>
                {formikProps.values.visitors[index].dependent !== 0 ? (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleSaveDependent(index)}
                  >
                    Save And Verify
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleAddDependent(index)}
                  >
                    Add Dependent
                  </Button>
                )}
              </Grid>
            )}
        </Grid>
      </Fragment>
    </Grid>
  );
};

export default VisitorForm;
