import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  MenuItem,
  Button,
} from "@mui/material";

import {
  LoadingButton,
  SelectField,
  SubheaderSection,
  TextField,
} from "../../components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useParams } from "react-router-dom";
import { useAlert, useAxios } from "../../hooks";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Fragment } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    background: "rgb(255, 255, 255)",
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
}));

const ContentBox = styled(Box)({
  padding: "30px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
});

const DependentPopup = ({
  eventID,
  setDependentList,
  dependentPopUp,
  setDependentPopUp,
}) => {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState({
    phoneNumber: visitorData.phoneNumber,
    name: "",
    emailId: "",
    gender: "",
  });

  const [loading, setLoading] = useState(false);

  return (
    <StyledDialog
      open={dependentPopUp}
      onClose={() => setDependentPopUp(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ padding: "0px" }}>
        <ContentBox>
          <Formik
            initialValues={formData}
            onSubmit={(formData, { setSubmitting, resetForm }) => {
              setLoading(true);
              axios({
                url: "event/visitor/add-dependent",
                method: "POST",
                data: {
                  phone_number: formData.phoneNumber,
                  name: formData.name,
                  email_id: formData.emailId,
                  gender: formData.gender,
                },
              })
                .then((response) => {
                  if (response.status) {
                    dispatch(
                      alert({
                        type: "success",
                        message: response.message.displayMessage,
                      })
                    );

                    axios({
                      url:
                        "event/visitor/get-dependent-list?phone_number=" +
                        visitorData.phoneNumber,
                      disableRedirect: true,
                    }).then((response) => {
                      if (response.status) {
                        setDependentList(response.data || []);
                      }
                    });
                  }

                  setLoading(false);

                  setDependentPopUp(false);
                })
                .catch((error) => {
                  resetForm();
                  setDependentPopUp(false);
                  setLoading(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight={"900"}>
                      Add Dependent
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <TextField
                      name="name"
                      label={"Fill Name"}
                      formikProps={formikProps}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <TextField
                      name="phoneNumber"
                      label={"Phone Number"}
                      disabled
                      formikProps={formikProps}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <TextField
                      name="emailId"
                      label={"Email Id"}
                      formikProps={formikProps}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <SelectField
                      label="Select Your Gender"
                      name="gender"
                      value={formikProps.values.gender}
                      options={[
                        { id: "Male", name: "Male" },
                        { id: "Female", name: "Female" },
                      ]}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={
                        formikProps.touched.district &&
                        formikProps.errors.district
                      }
                      required
                      xs={12}
                    />
                  </Grid>

                  <LoadingButton
                    loading={loading}
                    onClick={formikProps.handleSubmit}
                    fullWidth
                    backgroundColor="#F5F0E5"
                    style={{ fontWeight: 800, color: "black" }}
                  >
                    Submit
                  </LoadingButton>
                </Fragment>
              );
            }}
          </Formik>
        </ContentBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default DependentPopup;
