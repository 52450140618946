import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function EventBadge({
  index,
  eventID,
  formikProps,
  eventObject,
  badgeOptions,
}) {
  const handleEventBadgeSelect = (ID, index) => {
    const selectedEventId = ID;

    const selectedEvent = badgeOptions.find(
      (event) => event?._id === selectedEventId
    );

    formikProps.setFieldValue(
      `visitorData[${index}].badge_id`,
      formikProps.values.visitorData[index]?.badge_id ? "" : selectedEvent?._id
    );

    formikProps.setFieldValue(
      `visitorData[${index}].badge_id_amount`,
      formikProps.values.visitorData[index]?.badge_id_amount
        ? 0
        : parseInt(selectedEvent?.price)
    );

    if (eventID && !formikProps.values.eventAmount) {
      formikProps.setFieldValue(
        "eventAmount",
        parseInt(eventObject.ticket_cost)
      );
    }
  };

  function handleAddBadge(number, index, ID) {
    const selectedEventId = ID;

    const selectedEvent = badgeOptions.find(
      (event) => event?._id === selectedEventId
    );

    const count = formikProps.values?.badge?.find(
      (item) => item?.badge_id === ID
    )?.badge_count;

    formikProps.setFieldValue(`badge[${index}].badge_id`, selectedEvent?._id);

    formikProps.setFieldValue(
      `badge[${index}].badge_id_amount`,
      parseInt(selectedEvent?.price)
    );

    formikProps.setFieldValue(`badge[${index}].color`, selectedEvent?.color);
    formikProps.setFieldValue(
      `badge[${index}].name`,
      selectedEvent?.badge_name
    );

    number === 1
      ? formikProps.setFieldValue(`badge[${index}].badge_count`, count + 1 || 1)
      : formikProps.setFieldValue(
          `badge[${index}].badge_count`,
          count === undefined || count === 0 ? 0 : count - 1
        );
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          mb={3}
          fontWeight={700}
          color={"rgb(0 0 0 / 61%)"}
        >
          Select Event Badge
        </Typography>
      </Grid>
      {console.log(formikProps.values, "formikProps.values")}
      <Grid
        item
        xs={12}
        display={"flex"}
        width={0}
        sx={{
          border: " 1px solid rgb(232, 222, 207)",
          borderRadius: "12.5px",
          overflowY: "scroll",
          scrollbarWidth: "none",
          flexDirection: "row",
          padding: "10px 5px !important",
        }}
      >
        {badgeOptions.map((items, indx) => {
          return (
            <div
              style={{
                margin: "5px",
                background: items.color,
                padding: "10px 20px",
                borderRadius: "12.5px",
                // boxShadow:
                //   formikProps.values?.visitorData[index]?.badge_id ===
                //     items._id && "rgb(49, 49, 48) 0px 0px 1px 2px",

                pointerEvents:
                  formikProps.values.paymentStatue === "Pending" && "none",
              }}
              // onClick={() => handleEventBadgeSelect(items?._id, index)}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 900, textAlign: "start" }}>
                  {items.badge_name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 900,
                    textAlign: "start",
                    color: "rgb(233 175 71)",
                  }}
                >
                  Rs {items.price}
                </Typography>
              </Grid>

              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <RemoveIcon
                  onClick={() => handleAddBadge(0, indx, items?._id)}
                />
                <Typography
                  sx={{
                    fontWeight: 900,
                    textAlign: "start",
                    color: "rgb(233 175 71)",
                  }}
                >
                  {formikProps.values?.badge?.find(
                    (item) => item?.badge_id === items?._id
                  )?.badge_count || 0}
                </Typography>
                <AddIcon onClick={() => handleAddBadge(1, indx, items?._id)} />
              </Grid>
            </div>
          );
        })}
      </Grid>
    </Fragment>
  );
}
