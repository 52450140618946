import React, { useState, useEffect, Fragment } from "react";
import {
  OuterBox,
  CaptchaDisplay,
  SubheaderSection,
  MandatoryNote,
  LoadingButton,
} from "./../../components";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material";

const WelcomeScreen = ({ setSelectedOption, selectedOption, setProcced }) => {
  const [CaptchaError, setCaptchaError] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCaptcha = "";
    for (let i = 0; i < 6; i++) {
      randomCaptcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(randomCaptcha);
  };

  const handleCaptchaInputChange = (event) => {
    setCaptchaInput(event.target.value);
    setCaptchaError(false);
  };

  const handleProceed = () => {
    if (captchaInput !== captcha) {
      setCaptchaError("Please enter valid captcha");
    } else {
      setProcced(true);
    }
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <OuterBox>
      <SubheaderSection
        textAlign="left"
        title="Booking Type"
        content="Select your preferred course of action"
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              id="demo-simple-select-State"
              sx={{ paddingRight: "20px" }}
            >
              Select Booking Type
              <span
                style={{
                  color: "red",
                  float: "right",
                  paddingLeft: "5px",
                }}
              >
                *
              </span>
            </InputLabel>

            <Select
              label="Select Booking Type"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              value={selectedOption}
              onChange={handleDropdownChange}
            >
              <MenuItem value={"Book Appointment"}>Book Appointment</MenuItem>
              <MenuItem value={"Event Booking"}>Book Event</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label={
              <Fragment>
                Verify Captcha
                <span
                  style={{
                    color: "red",
                    float: "right",
                    paddingLeft: "5px",
                  }}
                >
                  *
                </span>
              </Fragment>
            }
            placeholder="Enter Captcha"
            value={captchaInput}
            onChange={handleCaptchaInputChange}
            error={!!CaptchaError}
            helperText={CaptchaError}
            inputProps={{
              maxLength: 6,
            }}
            autoComplete="off"
          />
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ paddingLeft: "10px", mt: 2 }}
        >
          <Grid item xs={12} sm={6}>
            <CaptchaDisplay
              captcha={captcha}
              generateCaptcha={generateCaptcha}
            />
          </Grid>
        </Grid>
      </Grid>
      <LoadingButton onClick={handleProceed}>Proceed</LoadingButton>
      <MandatoryNote />
    </OuterBox>
  );
};

export default WelcomeScreen;
