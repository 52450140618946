import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { TextField } from "../../components";
import { useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function Filter({
  dailogOpen,
  setDailogOpen,
  setFilterTransaction,
  setSelectOption,
  transaction,
}) {
  const classes = useStyles();
  const [filter, setFilter] = useState("");

  function handleClose() {
    setDailogOpen(false);
  }
  return (
    <Dialog
      open={dailogOpen}
      classes={{ paper: classes.paper }}
      onClose={handleClose}
    >
      <DialogTitle style={{ paddingBottom: "4px" }}>Fitler List</DialogTitle>
      <DialogContent>
        <TextField
          select
          label="Filter List"
          size="small"
          value={filter}
          onChange={(e) => {
            console.log(e.target.value, "filter");
            setFilter(e.target.value);
          }}
        >
          <MenuItem value="event">Events</MenuItem>
          <MenuItem value="appointment">Appointments</MenuItem>
        </TextField>
      </DialogContent>

      <DialogActions style={{ padding: "0px 24px 16px" }}>
        <Button
          onClick={() => {
            if (filter == "event") {
              setFilterTransaction(
                transaction.filter((item) => item.booking_type == 1)
              );
              setSelectOption("event");
            } else {
              setFilterTransaction(
                transaction.filter((item) => item.booking_type == 2)
              );
              setSelectOption("appointment");
            }
            setFilter("");
            handleClose();
          }}
          variant="contained"
          size="small"
        >
          Submit
        </Button>

        <Button
          onClick={handleClose}
          variant="contained"
          sx={{ background: "white", color: "black" }}
          size="small"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
